import React, {
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebase";
import { collection, doc, setDoc } from "firebase/firestore";
import { Paper } from "@mui/material";

export default function AddCompany() {

  const user = JSON.parse(localStorage.getItem('user'));
  const [name, setName] = useState('');
  const [dotNumber, setDotNumber] = useState('');
  const [status, setStatus] = useState(true);
  const timestamp = Date.now();
  const navigate = useNavigate();

  useEffect(() => {

    let user = localStorage.getItem('user');
    user = JSON.parse(user)


    if (user) {
      if (user.role === 'superadmin' || user.role === 'admin') {
        // navigate("/admin/company/add");
      }else {
        navigate("/dashboard"); 
      }
    }
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const messageRef = doc(collection(db, 'companies'));
    await setDoc(messageRef, {
      name: name,
      dotNumber: dotNumber,
      isActive: status,
      createdBy: user.uid,
      createdAt: timestamp,
      updatedBy: user.uid,
      updatedAt: timestamp
    });
    navigate('/admin/companies')
  }

  return (
    <form action="" onSubmit={handleUpdate}>
      <div className=" pt-20 pb-10">
        <div className="flex justify-between mb-5">
          <h1 className="text-lg font-bold">Add Company</h1>
        </div>
        <Paper sx={{ p: 2, width: '100%' }}>

          <div className="mb-5">
            <label htmlFor="price" className="block text-sm text-left font-medium leading-6 text-gray-900">
              Name
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                value={name}
                onInput={(e) => { setName(e.target.value) }}
                className="block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                placeholder="name"
              />

            </div>
          </div>
          <div className="mb-5">
            <label htmlFor="price" className="block text-sm text-left font-medium leading-6 text-gray-900">
              Dot Number
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                value={dotNumber}
                onInput={(e) => { setDotNumber(e.target.value) }}
                className="block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                placeholder="Dot Number"
              />

            </div>
          </div>
          <div className="text-left mb-5">
            <label htmlFor="price" className="mb-2 block text-sm text-left font-medium leading-6 text-gray-900">
              Status
            </label>
            <label className="inline-flex items-center mb-5 cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" onChange={() => { setStatus(!status) }} checked={status} />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
            </label>
          </div>
          <div className="text-left mb-5">
            <button className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" >
              Add
            </button>
          </div>
        </Paper>
      </div>
    </form>
  );
}

