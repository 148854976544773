import React, { useState, useEffect } from "react";
import { auth, db, doc, getDoc } from "../../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { MdMessage } from "react-icons/md";
import { TextField } from "@mui/material";

const Conversations = ({ chats, setSelectedChat, setIsCalling }) => {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")));
  const [conversations, setConversations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const getUser = async (id) => {
    const userQuery = query(doc(db, "users", id));
    const userSnapshot = await getDoc(userQuery);
    return { ...userSnapshot.data(), id: id };
  };

  const getRooms = async () => {
    try {
      const q = query(
        collection(db, "rooms"),
        where("userIds", "array-contains", user?.id),
      );
      const querySnapshot = await getDocs(q);

      const fetchedConversations = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          let val = doc.data();
          val.id = doc.id;
          val.contact = val.userIds.filter((x) => x !== user.id);

          // Fetch contact user details
          if (val.contact.length > 0) {
            val.contactUser = await getUser(val.contact[0]);
          } else {
            val.contactUser = null;
          }

          return val;
        }),
      );
      setConversations(fetchedConversations);
    } catch (e) {
      console.error("Error fetching conversations: ", e);
    }
  };

  useEffect(() => {
    if (!user) {
      let userData = localStorage.getItem(user);
      if (userData) setuser(JSON.parse(userData));
    }

    getRooms();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const normalizedSearchTerm = searchTerm
    .trim()
    .replace(/\s+/g, " ")
    .toLowerCase();

  const filteredContacts = normalizedSearchTerm
    ? conversations.filter((chat) =>
        chat?.contactUser?.firstName
          .toLowerCase()
          .includes(normalizedSearchTerm),
      )
    : conversations;

  const handleChat = async (contact) => {
    try {
      // const currentUser = auth.currentUser;
      // if (!currentUser) {
      //   console.error("User not authenticated.");
      //   return;
      // }

      // Query to check if chat room exists
      const qry = query(
        collection(db, "rooms"),
        where("userIds", "array-contains", user.id),
      );

      const querySnapshot = await getDocs(qry);

      let chatRoom = null;
      querySnapshot.forEach((doc) => {
        const chatRoomData = doc.data();
        if (chatRoomData.userIds.includes(contact.id)) {
          chatRoom = { id: doc.id, ...chatRoomData };
        }
      });

      // Navigate to the chat room with roomId
      let finalData = { ...chatRoom, contact };
      navigate(`/admin/chats/${chatRoom.id}`, { state: { room: finalData } });
    } catch (error) {
      console.error("Error creating chat room:", error);
    }
  };

  return (
    <>
      <div className="mt-16 h-full">
        <div className="font-semibold text-lg max-w-screen-lg min-w-min m-auto pt-2">
          Chat
        </div>
        <div className="container relative flex items-center justify-center max-w-screen-lg min-w-min h-12 rounded-lg focus-within:shadow-lg m-auto mb-6 pt-6">
          <TextField
            id="filled-basic"
            label="Search Here..."
            value={searchTerm}
            className="w-full bg-white mx-auto"
            onChange={handleSearchChange}
            variant="filled"
            sx={{
              "& .MuiInputBase-input": {
                "&:focus": {
                  boxShadow: "none !important",
                },
              },
            }}
          />
        </div>
        <div className="container m-auto max-w-screen-lg min-w-min bg-transparent overflow-auto h-full py-5 pb-28 shadow-2xl">
          {filteredContacts.length === 0 ? (
            <div>
              <h1 className="text-4xl text-gray-400 font-bold">
                No Chats Found
              </h1>
            </div>
          ) : (
            filteredContacts.map((chat) => (
              <>
                <div
                  className="grid grid-cols-[3rem_1fr] items-center gap-3 px-5 py-4 hover:bg-gray-300 bg-white rounded-lg my-3 mx-2 shadow-slate-300 shadow-md"
                  key={chat.id}
                  onClick={() => handleChat(chat?.contactUser)}
                  key={chat.id}
                >
                  <img
                    className="rounded-full bg-gray-800 w-12 h-12 object-cover"
                    src={
                      chat?.contactUser?.imageUrl ||
                      "https://img.freepik.com/free-vector/user-blue-gradient_78370-4692.jpg?t=st=1718795593~exp=1718799193~hmac=19f5df7027682d16b18ce8503b5140c6b59bbfea40291d37ec96bb0b3ec87b10&w=740"
                    }
                  />
                  <div>
                    <h4 className="text-gray-800 font-medium text-base flex justify-between">
                      <span>{chat?.contactUser?.firstName}</span>
                    </h4>
                    <p className="text-gray-500 font-thin text-base flex justify-between m-1 text-sm">
                      <span>{chat?.lastMessage}</span>
                    </p>
                    <p className="text-sm text-black/60 flex justify-between"></p>
                  </div>
                </div>
              </>
            ))
          )}
          {/* <div className="fixed bottom-6 right-5 bg-primary p-4 rounded-2xl">
            <MdMessage className="text-2xl text-white" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Conversations;
