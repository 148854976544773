import './App.css';
import Login from './Pages/Login';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Signup from './Pages/Signup';
import Profile from './Pages/Profile';
import Changepassword from './Pages/Changepassword';
import Forgotpassword from './Pages/Forgotpassword';
import Updateprofile from './Pages/Updateprofile';
import Companies from './Componets/admin/company/Companies';
import AdminLayout from './layout/AdminLayout';
import EditCompany from './Componets/admin/company/EditCompany';
import AddCompany from './Componets/admin/company/AddCompany';
import Users from './Componets/admin/user/Users';
import EditUser from './Componets/admin/user/EditUser';
import AddUser from './Componets/admin/user/AddUser';
import Admin from './Componets/admin/admin/Admin';
import EditAdmin from './Componets/admin/admin/EditAdmin';
import AddAdmin from './Componets/admin/admin/AddAdmin';
import Contacts from './Componets/admin/cdlmanager/Contacts';
import Chats from './Componets/admin/cdlmanager/Chats';
import Conversations from './Componets/admin/cdlmanager/Conversations';
import { Box } from '@mui/material';

function App() {

  const isAuth = localStorage.getItem('isloggedIn')
  const user = JSON.parse(localStorage.getItem('user'))

  return (
    <div className="App h-screen ">
      <Routes>
        {isAuth ? (
          <>
            <Route path="/Change-password" element={<Changepassword />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route path="/update-profile" element={<Updateprofile />} />
            <Route path="*" element={<Navigate to="/admin/Conversations" />} /> {/* Fallback route */}
            {(user.role === "subadmin" || user.role === "admin" || user.role === "Driver") &&
              <Route element={
                <AdminLayout>
                  <Outlet />
                </AdminLayout>
              }
              >
                <Route path="/admin/companies" element={<Companies />} />
                <Route path="/admin/company/:id/edit" element={<EditCompany />} />
                <Route path="/admin/company/add" element={<AddCompany />} />

                <Route path="/admin/user" element={<Users />} />
                <Route path="/admin/user/:id/edit" element={<EditUser />} />
                <Route path="/admin/user/add" element={<AddUser />} />

                <Route path="/admin/admins" element={<Admin />} />
                <Route path="/admin/admin/:id/edit" element={<EditAdmin />} />
                <Route path="/admin/admin/add" element={<AddAdmin />} />
                <Route path="/admin/Conversations" element={<Conversations />} />
                <Route path="/admin/Contacts" element={<Contacts />} />
                <Route path="/admin/chats/:id" element={<Chats />} />
              </Route>
            }
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<Forgotpassword />} />
            <Route path="*" element={<Navigate to="/login" />} />

          </>
        )}
      </Routes>

    </div>
  );
}

export default App;
