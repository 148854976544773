import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  collection,
  doc,
  updateDoc,
  onSnapshot,
  query,
  orderBy,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "react-chat-elements/dist/main.css";
import { IoMdSend } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { CgAttachment } from "react-icons/cg";
import { auth, db, storage } from "../../../firebase";
import ImgsViewer from "react-images-viewer";

function Chats() {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false);
  const [limit, setLimit] = useState(15);
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")));
  const [contact, setContact] = useState("");
  const [companyName, setCompanyName] = useState("");
  const messagesEndRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageViewOpen, setImageViewOpen] = useState(false);
  const [imageViewImageSrc, setImageViewImageSrc] = useState('false');

  const [previewImage, setPreviewImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();
  const className =
    "sm:max-w-[80%] md:max-w-[60%] lg:max-w-[40%] sm:w-700 sm py-1 px-1.5 rounded-xl shadow-md text-sm bg-gray-200 break-all text-black flex items-end ";

  useEffect(() => {
    if (!user) {
      let userData = localStorage.getItem("user");
      if (userData) setuser(JSON.parse(userData));
    }
    if (location?.state?.room) {
      console.log(location.state.room.contact);
      setContact(location.state.room.contact);
      getDoc(doc(db, 'companies', location.state.room.contact.companyId)).then((querySnapshot) => {
        const data = querySnapshot.data();
        console.log('companiesname',data.name);
        setCompanyName(data.name)
      }).catch((e)=>{
        console.log(e);
      });
    }
    fetchMessages();
  }, [limit, location?.state?.room]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const fetchMessages = async () => {
    const roomId = location?.state?.room?.id;
    if (!roomId) return;

    const messagesRef = collection(db, "rooms", roomId, "messages");
    const messagesQuery = query(messagesRef, orderBy("createdAt"));

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const messagesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesList);
    });

    return () => unsubscribe();
  };

  const handleSendPressed = async (event) => {
    event.preventDefault();
    const nowInNewYork = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York',
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    const [datePart, timePart] = nowInNewYork.split(', ');

    // Reformat the date part (MM/DD/YYYY -> YYYY-MM-DD)
    const [month, day, year] = datePart.split('/');
    const formattedDate = `${year}-${month}-${day}`;
    
    // Combine date and time to create ISO format
    const timestamp = `${formattedDate}T${timePart}`;

    const message = {
      // authorId: user.currentUser.uid,
      authorId: user.id,
      text: newMessage,
      type: "text",
      createdAt: timestamp,
      updatedAt: timestamp,
    };
    await sendMessage(message);
    setNewMessage("");
  };

  const sendImageMessage = async () => {
    if (!imageFile) return;

    setIsAttachmentUploading(true);
    setIsUploading(true);
    const storageRef = ref(storage, `images / ${imageFile.name}`);
    await uploadBytes(storageRef, imageFile);
    const downloadURL = await getDownloadURL(storageRef);
    const nowInNewYork = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York',
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    const [datePart, timePart] = nowInNewYork.split(', ');

    // Reformat the date part (MM/DD/YYYY -> YYYY-MM-DD)
    const [month, day, year] = datePart.split('/');
    const formattedDate = `${year}-${month}-${day}`;
    
    // Combine date and time to create ISO format
    const timestamp = `${formattedDate}T${timePart}`;

    const message = {
      // authorId: auth.currentUser.uid,
      authorId: user.id,
      type: "image",
      url: downloadURL,
      createdAt: timestamp,
      updatedAt: timestamp,
    };

    await sendMessage(message);
    setIsAttachmentUploading(false);
    setIsUploading(false);
    setIsModalOpen(false);
    closeModal();
  };

  const handleImageSelection = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const previewURL = URL.createObjectURL(file);
      setPreviewImage(previewURL);
      setImageFile(file);
      setIsModalOpen(true);
    }
    event.target.value = null;
  };

  const sendMessage = async (message) => {
    const roomId = location?.state?.room?.id;
    if (!roomId) return;

    const messageRef = doc(collection(db, "rooms", roomId, "messages"));
    await setDoc(messageRef, message);
    await updateLastMessage(message.text || "🖼️ Media file");
  };

  const updateLastMessage = async (text) => {
    const roomId = location?.state?.room?.id;

    // Set your character limit here
    const charLimit = 20;

    // Check if the text length exceeds the limit
    if (text.length > charLimit) {
      text = text.substring(0, charLimit) + "...";
    }

    const roomRef = doc(db, "rooms", roomId);
    await updateDoc(roomRef, { lastMessage: text });
  };

  const navigateToDashbord = () => {
    navigate("/admin/Conversations");
  };

  const closeModal = () => {
    setPreviewImage(null);
    setImageFile(null);
    setIsModalOpen(false);
  };
  const imageViewClose = () => {
    setImageViewOpen(false)
  };

  return (
    <>

      <ImgsViewer
        imgs={[
          { src: imageViewImageSrc },
        ]}
        isOpen={imageViewOpen}
        // onClickPrev={this.gotoPrevious}
        // onClickNext={this.gotoNext}
        onClose={imageViewClose}
      />
      <div className="mt-16 h-full relative">
        <header className="bg-primary-600 w-full flex items-center justify-center  py-2.5 px-2 bg-blue-400 text-black gap-3 z-10">
          <FaArrowLeft className="text-xl" onClick={navigateToDashbord} />
          <img
            src={
              contact.imageUrl ||
              "https://img.freepik.com/free-vector/user-blue-gradient_78370-4692.jpg?t=st=1718795593~exp=1718799193~hmac=19f5df7027682d16b18ce8503b5140c6b59bbfea40291d37ec96bb0b3ec87b10&w=740"
            }
            className="rounded-full w-10 h-10 object-cover border-2 border-black-400"
          />
          <div className="flex flex-col mr-auto">
            <h4 className=" font-bold flex items-center">{contact.firstName} ● {companyName} ● {contact.dotNumber}</h4>
          </div>
        </header>
        <div
          className="overflow-auto"
          style={{ height: "calc(100% - 132px)", padding: "16px 8px" }}
        >
          {messages.map((message, index) => (
            <div key={index}>
              {message.authorId !== contact?.id ? (
                <div className="flex justify-end">
                  <div
                    className={`message bg-blue-400 text-black mt-5 -mr-0 text-3xl ${className}`}
                  >
                    {message.text ? (
                      <p className="px-1.5 text-lg  text-start">
                        {message.text}
                      </p>
                    ) : (
                      <img
                        onClick={()=>{setImageViewImageSrc(message.url); setImageViewOpen(true) }}
                        src={message.url}
                        alt="attachment"
                        className="h-40"
                      />
                    )}
                  </div>
                  <img
                    src={
                      user.imageUrl ||
                      "https://img.freepik.com/free-vector/user-blue-gradient_78370-4692.jpg"
                    }
                    className="rounded-full w-5 h-5 object-cover border-2 border-gray-200"
                  />
                </div>
              ) : (
                <div className="flex justify-start">
                  <img
                  
                    src={
                      contact.imageUrl ||
                      "https://img.freepik.com/free-vector/user-blue-gradient_78370-4692.jpg"
                    }
                    className="rounded-full w-5 h-5 object-cover border-2 border-gray-300 mb-3"
                  />
                  <div className="message w-max max-w-[30%] py-1 px-1.5 mt-5 rounded-xl text-sm bg-[#435f7a] break-all  text-black flex items-end ">
                    {message.text ? (
                      <p className="w-max py-0 px-1.5  text-lg text-start text-white">
                        {message.text}
                      </p>
                    ) : (
                      <img
                      onClick={()=>{setImageViewImageSrc(message.url); setImageViewOpen(true) }}
                        src={message.url}
                        alt="attachment"
                        className="h-40"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <form
          onSubmit={handleSendPressed}
          className="m-auto w-full absolute right-0 left-0 bottom-0 bg-blue-400 p-2 pt-1.5 flex gap-2 items-center group"
        >
          <div className="flex-grow flex items-center gap-3 bg-white border p-2 rounded-full text-black/50">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message"
              className="flex-grow typ text-black  break-words ml-2"
            />
            <div className="fileUpload2 top">
              <input
                type="file"
                className="upload "
                accept="image/*"
                onChange={handleImageSelection}
              />
              <span>
                <CgAttachment className=" text-xl text-gray-700" />
              </span>
            </div>
          </div>
          <button
            type="submit"
            onClick={() => sendMessage}
            className="bg-primary p-3 rounded-full"
          >
            <IoMdSend className=" text-xl text-gray-700" />
          </button>
        </form>
      </div>
      {isModalOpen && (
        <div className="modal2 z-20">
          <div className="modal2-content min-w-[350px]">
            <div className="relative">
              <div className="flex justify-between mx-4 my-2">
                <h1 className="text-start text-lg">Media File</h1>
                <button className="m-0 text-3xl" onClick={closeModal}>
                  &times;
                </button>
              </div>
              <img
                src={previewImage}
                alt="preview"
                className="relative w-full h-44 p-5 items-center"
              />
              <div className="flex justify-center pb-2">
                {isUploading ? (
                  <>
                    <div className="loader "></div>
                  </>
                ) : (
                  <button
                    onClick={sendImageMessage}
                    className=" flex mr-2 send-button  "
                  >
                    Send
                    <IoMdSend className=" text-xl text-white ml-1" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Chats;
