import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase"; // Ensure to import your Firebase instance
import { updateProfile, updateEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Link, TextField } from "@mui/material";
import { doc, setDoc, updateDoc } from "firebase/firestore";


function Updateprofile() {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const navigate = useNavigate();
  const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
  //   const currentUser = auth.currentUser;
  //   if (currentUser) {
  //   }
  // }, []);
  setEmail(user.email || "");
  setFullName(user?.firstName+' '+user.lastName || "");
  },[user]  );  
  const handleUpdateProfile = async () => {
    // const userdata = auth.currentUser;
    // if (userdata) {
      try {
        // await updateProfile(userdata, { displayName: fullName });
        // await updateEmail(userdata, email);
        // localStorage.setItem("user", JSON.stringify({ ...user, ...userdata }));
        // want to update user data also from user table in database
        const nameParts = fullName.trim().split(" ");
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(" ");
        // await db.collection("users").doc(user.uid).update({
        //   email: email,
        //   firstName,
        //   lastName,
        // });
        await updateDoc(doc(db, "users", user.id), {
          email: email,
          firstName,
          lastName,
        });

        user.email = email;
        user.firstName = firstName;
        user.lastName = lastName;
        localStorage.setItem("user", JSON.stringify(user));
        navigate("/admin/companies");
        toast.success("Profile updated successfully!");
      } catch (error) {
        console.error("Error updating profile", error);
      }
    }

  return (
    <>
      <ToastContainer />
      <div className="flex max-h-full h-screen flex-1 flex-col justify-center content-center px-6 py-12 lg:px-8">
        <div className="card-border top-card-border p-20 m-auto rounded-lg shadow-xl content-center">
          <div className="sm:mx-auto sm:w-full w-50 sm:max-w-sm">
            <h1 className="text-center main-color text-3xl font-bold leading-9 tracking-tight text-gray-900">
              Edit Profile
            </h1>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className="space-y-6"
              noValidate
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateProfile();
              }}
            >
              <div>
                <div className="relative h-11 w-full min-w-[200px]">
                  <TextField
                    id="filled-basic"
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full bg-white mx-auto"
                    variant="standard"
                    sx={{
                      "& .MuiInputBase-input": {
                        "&:focus": {
                          boxShadow: "none !important",
                        },
                      },
                    }}
                    required
                  />
                </div>
              </div>
              <div>
                <div className="relative h-11 w-full min-w-[200px]">
                  <TextField
                    id="filled-basic"
                    label="FullName"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="w-full bg-white mx-auto"
                    variant="standard"
                    sx={{
                      "& .MuiInputBase-input": {
                        "&:focus": {
                          boxShadow: "none !important",
                        },
                      },
                    }}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <button
                  type="submit"
                  className="flex w-full uppercase justify-center bg-color font-bold rounded-md bg-blue-400 px-3 py-1.5 text-base leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-blue-500 disabled:cursor-not-allowed"
                  disabled={!(email && fullName)}
                >
                  UPDATE
                </button>
                <Link href="/admin/companies" sx={{ textDecoration: "none" }}>
                  <button
                    type="button"
                    className="flex w-full uppercase justify-center font-bold rounded-md bg-gray-400 px-3 py-1.5 text-base leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-gray-500"
                  >
                    Back
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Updateprofile;
