import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import {
  auth,
  db,
  createUserWithEmailAndPassword,
  updateProfile,
  collection,
  getDocs,
  setDoc,
  doc,
} from "../firebase";
import { query, where } from "firebase/firestore";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

function Signup() {
  const { register, handleSubmit } = useForm();
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companySnapshot = await getDocs(collection(db, "companies"));
        const companyList = companySnapshot.docs
          .filter((doc) => doc.data().isActive)
          .map((doc) => ({ id: doc.id, ...doc.data() }));
        setCompanies(companyList);
      } catch (error) {
        console.error("Error getting companies:", error);
      }
    };
    fetchCompanies();
  }, []);

  const onSubmit = async (data) => {
    const { email, password, name, companyId, role, dotNumber } = data;
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      let user = userCredential.user;
      await updateProfile(auth.currentUser, {
        displayName: name,
        // photoURL: userImage,
      });
      const nameParts = name.trim().split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(" ");
      await setDoc(doc(db, "users", user.uid), {
        companyId,
        role,
        isActivated: true,
        firstName,
        lastName,
        dotNumber,
        password,
        email,
        // Never store passwords in Firestore
        id: user.uid,
      });
      let userDoc = await getDocs(
        query(collection(db, "users"), where("email", "==", email)),
      );

      if (userDoc) {
        userDoc = userDoc.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        user = { ...user, ...userDoc[0] };
      }

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("isloggedIn", "true");
      window.location.reload();

      if (role === "subadmin") {
        navigate("/admin");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error registering user:", error);
      if (error.code === "auth/weak-password") {
        alert("The password provided is too weak.");
      } else if (error.code === "auth/email-already-in-use") {
        alert("The account already exists for that email.");
      }
    }
  };

  return (
    <>
      <div className="flex h-full overflow-auto flex-1 flex-col justify-center content-center px-6 py-12 lg:px-8 ">
        <div className="card-border w-fit m-auto mt-10 p-5 rounded-lg top-card-border min-w-[350px]">
          <div className="sm:mx-auto sm:w-full w-50 sm:max-w-sm">
            <h1 className="text-center main-color text-4xl font-bold leading-9 tracking-tight text-gray-900">
              SIGN UP
            </h1>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="relative h-11 w-full min-w-[200px]">
                  <TextField
                    id="filled-basic"
                    label="Email Address"
                    type="email"
                    className="w-full bg-white mx-auto"
                    {...register("email")}
                    variant="standard"
                    sx={{
                      "& .MuiInputBase-input": {
                        "&:focus": {
                          boxShadow: "none !important",
                        },
                      },
                    }}
                    required
                  />
                </div>
              </div>
              <div>
                <div className="relative h-11 w-full min-w-[200px]">
                  <TextField
                    id="filled-basic"
                    label="Full Name"
                    className="w-full bg-white mx-auto"
                    {...register("name")}
                    variant="standard"
                    sx={{
                      "& .MuiInputBase-input": {
                        "&:focus": {
                          boxShadow: "none !important",
                        },
                      },
                    }}
                    required
                  />
                </div>
              </div>
              <div>
                <div className="relative h-11 w-full min-w-[200px]">
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: 120, width: "100%" }}
                  >
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select
                      labelId="role-label"
                      id="role-dropdown"
                      label="Role"
                      {...register("role")}
                    >
                      <MenuItem value="Driver">Driver</MenuItem>
                      <MenuItem value="subadmin">Admin</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <div className="relative h-11 w-full min-w-[200px]">
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: 120, width: "100%" }}
                  >
                    <InputLabel id="role-label">Company</InputLabel>
                    <Select
                      labelId="role-label"
                      id="role-dropdown"
                      label="Company"
                      {...register("companyId")}
                    >
                      {companies.map((company) => (
                        <MenuItem value={company.id} key={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div>
                <div className="relative h-11 w-full min-w-[200px]">
                  <TextField
                    id="filled-basic"
                    label="Dot Number"
                    className="w-full bg-white mx-auto"
                    {...register("dotNumber")}
                    variant="standard"
                    sx={{
                      "& .MuiInputBase-input": {
                        "&:focus": {
                          boxShadow: "none !important",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="relative h-11 w-full min-w-[200px]">
                  <TextField
                    id="filled-basic"
                    label="Password"
                    type="password"
                    className="w-full bg-white mx-auto"
                    {...register("password")}
                    variant="standard"
                    sx={{
                      "& .MuiInputBase-input": {
                        "&:focus": {
                          boxShadow: "none !important",
                        },
                      },
                    }}
                    required
                  />
                </div>
              </div>
              <div></div>
              <p className="mt-10 text-center text-sm text-gray-500">
                By clicking SIGN UP, you agree to our{""}
                <a
                  href="#"
                  className="font-semibold leading-6 main-color underline"
                >
                  Terms and Conditions
                </a>
                and that you have read our
                <a
                  href="#"
                  className="font-semibold leading-6 main-color underline"
                >
                  Privacy Policy
                </a>
              </p>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center bg-color font-semibold rounded-md px-3 py-1.5 text-base leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  SIGN UP
                </button>
              </div>
            </form>
            <div className="text-sm my-2 mt-6">
              <Link
                to="/login"
                className="text-gray-600 hover:text-blue-500 hover:underline"
              >
                Already have an account? Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
