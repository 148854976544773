import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PeopleIcon from "@mui/icons-material/People";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ChatIcon from "@mui/icons-material/Chat";
import LogoutIcon from "@mui/icons-material/Logout";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      window.location.reload();
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  return (
    <Box
      className="mt-16"
      sx={{
        backgroundColor: "rgb(96 165 250)",
        position: "fixed",
        width: "250px",
        display: { xs: "none", sm: "block" },
        height: "calc(100% - 64px)",
      }}
    >
      <List>
        {user.role === "admin" && (
          <>
            <ListItem sx={{ fontSize: "1px" }} disablePadding>
              <Link to="/admin/companies" style={{ width: "100%" }}>
                <ListItemButton sx={{ fontWeight: "900" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <BusinessIcon width={22} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                    primary="Companies "
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider></Divider>
          </>
        )}

        {user.role === "admin" && (
          <>
            <ListItem sx={{ fontSize: "1px" }} disablePadding>
              <Link to="/admin/user" style={{ width: "100%" }}>
                <ListItemButton sx={{ fontWeight: "900" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <PeopleIcon width={22} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                    primary="User"
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider></Divider>
          </>
        )}

        {user.role === "admin" && (
          <>
            <ListItem sx={{ fontSize: "1px" }} disablePadding>
              <Link to="/admin/admins" style={{ width: "100%" }}>
                <ListItemButton sx={{ fontWeight: "900" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <AdminPanelSettingsIcon width={22} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                    primary="Admins"
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider></Divider>
          </>
        )}

        {(user.role === "subadmin" || user.role === "Driver") && (
          <>
            <ListItem sx={{ fontSize: "1px" }} disablePadding>
              <Link to="/admin/Contacts" style={{ width: "100%" }}>
                <ListItemButton sx={{ fontWeight: "900" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <PermContactCalendarIcon width={22} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                    primary="Contact"
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider></Divider>
          </>
        )}

        <ListItem sx={{ fontSize: "1px" }} disablePadding>
          <Link to="/admin/Conversations" style={{ width: "100%" }}>
            <ListItemButton sx={{ fontWeight: "900" }}>
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <ChatIcon width={22} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                primary="Chat"
              />
            </ListItemButton>
          </Link>
        </ListItem>
        <Divider></Divider>

        <ListItem sx={{ fontSize: "1px" }} disablePadding>
          <Link style={{ width: "100%" }}>
            <ListItemButton
              sx={{ fontWeight: "900" }}
              onClick={() => handleSignOut()}
            >
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <LogoutIcon width={22} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                primary="Logout"
              />
            </ListItemButton>
          </Link>
        </ListItem>

        <Divider></Divider>
      </List>
    </Box>
  );
};

export default Sidebar;
