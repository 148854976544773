import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ListItemIcon } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GridViewIcon from "@mui/icons-material/GridView";
import ChatIcon from "@mui/icons-material/Chat";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

const drawerWidth = 240;

export default function Navbar(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const isimageURL = JSON.parse(localStorage.getItem("user"))?.imageUrl;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      Navigate("/login");
      window.location.reload();
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box display={"flex"} justifyContent={"center"} sx={{ my: 2 }}>
        <img width={"90px"} src={require("../assets/logo.png")} />
      </Box>
      <Divider />
      <List>
        {(user.role === "subadmin" || user.role === "admin") && (
          <>
            <ListItem sx={{ fontSize: "1px" }} disablePadding>
              <Link to="/admin/companies" style={{ width: "100%" }}>
                <ListItemButton sx={{ fontWeight: "900" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <BusinessIcon width={22} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                    primary="Companies "
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider></Divider>
          </>
        )}

        {(user.role === "subadmin" || user.role === "admin") && (
          <>
            <ListItem sx={{ fontSize: "1px" }} disablePadding>
              <Link to="/admin/user" style={{ width: "100%" }}>
                <ListItemButton sx={{ fontWeight: "900" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <PeopleIcon width={22} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                    primary="User"
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider></Divider>
          </>
        )}

        {(user.role === "subadmin" || user.role === "admin") && (
          <>
            <ListItem sx={{ fontSize: "1px" }} disablePadding>
              <Link to="/admin/admins" style={{ width: "100%" }}>
                <ListItemButton sx={{ fontWeight: "900" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <AdminPanelSettingsIcon width={22} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                    primary="Admins"
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider></Divider>
          </>
        )}

        {(user.role === "subadmin" || user.role === "Driver") && (
          <>
            <ListItem sx={{ fontSize: "1px" }} disablePadding>
              <Link to="/admin/Contacts" style={{ width: "100%" }}>
                <ListItemButton sx={{ fontWeight: "900" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <PermContactCalendarIcon width={22} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                    primary="Contact"
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider></Divider>
          </>
        )}

        <ListItem sx={{ fontSize: "1px" }} disablePadding>
          <Link to="/admin/Conversations" style={{ width: "100%" }}>
            <ListItemButton sx={{ fontWeight: "900" }}>
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <ChatIcon width={22} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ sx: { fontWeight: "600" } }}
                primary="Chat"
              />
            </ListItemButton>
          </Link>
        </ListItem>
        <Divider></Divider>
      </List>
    </Box>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{ backgroundColor: "#fff", height: "64px" }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton
              color="black"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ ml: 1, flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              <img width={"90px"} src={require("../assets/logo.png")} />
            </Typography>

            <Box sx={{ color: "black" }}>
              <Button onClick={handleClick}>
                <div>
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open user menu</span>

                  {isimageURL ? (
                    <img
                      src={isimageURL}
                      className="h-10 w-10 rounded-full"
                      alt="Fetched from Firebase"
                    />
                  ) : (
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://img.freepik.com/free-vector/user-blue-gradient_78370-4692.jpg?t=st=1718795593~exp=1718799193~hmac=19f5df7027682d16b18ce8503b5140c6b59bbfea40291d37ec96bb0b3ec87b10&w=740"
                      alt=""
                    />
                  )}
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className=""
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem>
                  <Link
                    className="block px-5 text-sm text-gray-700 font-start"
                    to="/update-profile"
                  >
                    Edit Profile
                  </Link>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>

      {isDialogOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3 className="font-bold text-xl">Logout</h3>
            <p>Are you sure you want to logout?</p>
            <button className="yes" onClick={handleSignOut}>
              Yes
            </button>
            <button className="no" onClick={() => setIsDialogOpen(false)}>
              No
            </button>
          </div>
        </div>
      )}
    </>
  );
}
