import React, {
  useState,
  useEffect
} from "react";

import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../../firebase";
import { collection, getDocs, updateDoc, doc, setDoc } from "firebase/firestore";
import { MenuItem, Paper, Select } from "@mui/material";

export default function AddUser() {

  const user = JSON.parse(localStorage.getItem('user'));
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dotNumber, setDotNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const nowInNewYork = new Date().toLocaleString('en-US', {
    timeZone: 'America/New_York',
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
  const [datePart, timePart] = nowInNewYork.split(', ');

  // Reformat the date part (MM/DD/YYYY -> YYYY-MM-DD)
  const [month, day, year] = datePart.split('/');
  const formattedDate = `${year}-${month}-${day}`;
  
  // Combine date and time to create ISO format
  const timestamp = `${formattedDate}T${timePart}`;

  const navigate = useNavigate();

  useEffect(() => {

    let user = localStorage.getItem('user');
    user = JSON.parse(user)


    if (user) {
      if (user.role === 'superadmin' || user.role === 'admin') {
        // navigate("/admin/user/add");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const userRef = doc(collection(db, 'users'));
    await setDoc(userRef, {
      lastName: lastName,
      firstName: firstName,
      email: email,
      dotNumber: dotNumber,
      companyId: companyId,
      password: password,
      isActivated: status,
      role: 'Driver',
      createBy: user.uid,
      createAt: timestamp,
      updatedBy: user.uid,
      updatedAt: timestamp
    });
    navigate('/admin/user')
  }
  useEffect(() => {

    getDocs(collection(db, "companies")).then(async (querySnapshot) => {
      ;
      const fetchedCompanies = await Promise.all(
        querySnapshot.docs.map(async doc => {
          return { id: doc.id, ...doc.data() };
        })
      );
      setCompanies(fetchedCompanies)
    }).catch((e) => {
    })
  }, [])


  return (
    <form action="" onSubmit={handleUpdate}>
      <div className=" pt-20 pb-10">
        <div className="flex justify-between mb-5">
          <h1 className="text-lg font-bold">Add User</h1>
        </div>
        <Paper sx={{ p: 2, width: '100%' }}>

          <div className="mb-5">
            <label htmlFor="price" className="block text-sm text-left font-medium leading-6 text-gray-900">
              First Name
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                value={firstName}
                onInput={(e) => { setFirstName(e.target.value) }}
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                placeholder="First Name"
              />

            </div>
          </div>
          <div className="mb-5">
            <label htmlFor="price" className="block text-sm text-left font-medium leading-6 text-gray-900">
              Last Name
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                value={lastName}
                onInput={(e) => { setLastName(e.target.value) }}
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                placeholder="Last Name"
              />

            </div>
          </div>
          <div className="mb-5">
            <label htmlFor="price" className="block text-sm text-left font-medium leading-6 text-gray-900">
              Email
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                value={email}
                onInput={(e) => { setEmail(e.target.value) }}
                type="email"
                className="block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                placeholder="Email"
              />

            </div>
          </div>
          <div className="mb-5">
            <label htmlFor="price" className="block text-sm text-left font-medium leading-6 text-gray-900">
              Password
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                value={password}
                onInput={(e) => { setPassword(e.target.value) }}
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                placeholder="Password"
              />

            </div>
          </div>

          <div className="mb-5">
            <label htmlFor="price" className="block text-sm text-left font-medium leading-6 text-gray-900">
              Dot Number
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                value={dotNumber}
                onInput={(e) => { setDotNumber(e.target.value) }}
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                placeholder="Dot Number"
              />

            </div>
          </div>
          <div className="mb-5">
            <label htmlFor="price" className="block text-sm text-left font-medium leading-6 text-gray-900">
              Company
            </label>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={companyId}
              label="Role"
              sx={{ width: '100%', height: 40 }}
              onChange={(e) => setCompanyId(e.target.value)}
            >
              {
                companies.map((item) =>
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                )
              }
            </Select>
          </div>
          <div className="text-left mb-5">
            <label htmlFor="price" className="mb-2 block text-sm text-left font-medium leading-6 text-gray-900">
              Status
            </label>
            <label className="inline-flex items-center mb-5 cursor-pointer">
              <input type="checkbox" value="1" className="sr-only peer" onChange={() => { setStatus(!status) }} checked={status} />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
            </label>
          </div>
          <div className="text-left mb-5">
            <button className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" >
              Add
            </button>
          </div>
        </Paper>

      </div>
    </form>

  );
}

