import React from "react";
import Sidebar from "../Componets/admin/Sidebar";

import Box from "@mui/material/Box";
import Navbar from "./Navbar";

export default function AdminLayout({ children }, props) {
  return (
    <>
      <Navbar />
      <Sidebar />
      <Box
        sx={{
          padding:'15px',
          backgroundColor: "rgb(243 244 246)",
          paddingLeft: { sm: "265px" },
          height: "calc(100% - 64px)",
        }}
      >
        {children}
      </Box>
    </>
  );
}
