import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { TextField } from "@mui/material";

function Forgotpassword() {
  const [email, setEmail] = useState("");

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const recoverPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Check your email");
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      let errorMessage = error.message;
      if (error.code === "auth/user-not-found") {
        errorMessage = "User is not registered";
      }
      alert(errorMessage);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className=" flex h-full overflow-auto flex-1 flex-col justify-center content-center px-6 py-12 lg:px-8 ">
        <div className="  card-border p-20 m-auto mt-10 rounded-lg top-card-border content-center min-w-[350px]">
          <div class="flex pt-10 justify-center">
            <img width={"300px"} src={require("../assets/logo.png")} />
          </div>
          <div className="fle max-h-full flex-1 flex-col justify-center content-center px-6 py-12 lg:px-8">
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form
                className="space-y-6"
                onSubmit={(e) => {
                  recoverPassword(e);
                }}
              >
                <div>
                  <div class="relative h-11 w-full min-w-[200px]">
                    <TextField
                      id="filled-basic"
                      label="Email Address"
                      type="email"
                      value={email}
                      className="w-full bg-white mx-auto"
                      onChange={(e) => setEmail(e.target.value)}
                      variant="standard"
                      sx={{
                        "& .MuiInputBase-input": {
                          "&:focus": {
                            boxShadow: "none !important",
                          },
                        },
                      }}
                      required
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full uppercase justify-center bg-color font-bold rounded-md bg-blue-400 px-3 py-1.5 text-base leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-blue-500"
                  >
                    Recover Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgotpassword;
