import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { auth, db, signInWithEmailAndPassword } from "../firebase";
import { getDocs, query, collection, where } from "firebase/firestore";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@mui/material";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const navigate = useNavigate();

  //* function for signing in
  const handleSignIn = async (data) => {
    setIsSigningIn(true);
    try {
      // const userCredential = await signInWithEmailAndPassword(
      //   auth,
      //   email,
      //   password,
      // );

      // let user = userCredential.user;
      let user;
      let userDoc = await getDocs(
        query(
          collection(db, "users"),
          where("email", "==", email),
          where("password", "==", password)
        )
      );

      if (userDoc) {
        userDoc = userDoc.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        // user = { ...user, ...userDoc[0] };
        user = { ...userDoc[0] };
        if (user?.id) {
          console.log("user", user);
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("isloggedIn", "true"); // Store as a string

          toast.success("Signed in successfully!");

          if (user.role === "admin" || user.role === "admin") {
            localStorage.setItem("isloggedIn", "true"); // Store as a string

            navigate("/admin/companies");
            window.location.reload();
          } else if (user.role === "Driver" || user.role === "subadmin") {
            localStorage.setItem("isloggedIn", "true"); // Store as a string

            navigate("/admin/Conversations");
            window.location.reload();
          } else {
            toast.error("Invalid email or password. Please try again.");
          }
        } else {
          toast.error("Invalid email or password. Please try again.");
        }
      } else {
        toast.error("Invalid email or password. Please try again.");
      }
    } catch (error) {
      console.log(error);
      handleSignInError(error);
    } finally {
      setIsSigningIn(false);
    }
  };

  const resetemail = () => {
    localStorage.setItem("email", email);
  };

  const handleSignInError = (error) => {
    if (error.code === "auth/user-not-found") {
      toast.error("No user found for that email. Please create an account.");
    } else if (
      ["auth/wrong-password", "auth/invalid-credential"].includes(error.code)
    ) {
      toast.error("Wrong password provided.");
    } else {
      console.error("Error signing in:", error);
      toast.error("An error occurred during sign-in. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex h-full overflow-auto flex-1 flex-col justify-center content-center  align-middle px-6 py-12 lg:px-8">
        <div className="card-border w-fit m-auto mt-10 top-card-border rounded-lg min-w-[350px]">
          <div className="flex pt-10 justify-center">
            <img width={"200px"} src={require("../assets/logo.png")} />
          </div>
          <div className="fle max-h-full flex-1 flex-col justify-center content-center px-6 py-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full w-50 sm:max-w-sm">
              <h2 className="text-lg text-center text-gray-600">
                Sign In To Your Account
              </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" action="#" method="POST">
                <div>
                  <div className="relative h-11 w-full min-w-[200px]">
                    <TextField
                      id="filled-basic"
                      label="Email"
                      type="email"
                      value={email}
                      className="w-full bg-white mx-auto"
                      onChange={(e) => setEmail(e.target.value)}
                      variant="standard"
                      sx={{
                        "& .MuiInputBase-input": {
                          "&:focus": {
                            boxShadow: "none !important",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="relative h-11 w-full min-w-[200px]">
                    <TextField
                      id="filled-basic"
                      label="Password"
                      type="password"
                      className="w-full bg-white mx-auto"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      variant="standard"
                      sx={{
                        "& .MuiInputBase-input": {
                          "&:focus": {
                            boxShadow: "none !important",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <button
                    type="submit"
                    onClick={handleSignIn}
                    disabled={isSigningIn}
                    className="flex w-full justify-center bg-color font-semibold rounded-md px-3 py-1.5 text-base leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    SIGN IN
                  </button>
                </div>
              </form>
              <div className="text-sm my-2 mt-6">
                <Link
                  to="/signup"
                  onClick={resetemail}
                  className="text-gray-600 hover:text-blue-500 hover:underline"
                >
                  Don't have an account? Signup
                </Link>
              </div>
              <div className="text-sm">
                <Link
                  to="/forgot-password"
                  onClick={resetemail}
                  className="text-gray-600 hover:text-blue-500 hover:underline"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
