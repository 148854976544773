import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { auth, db } from "../../../firebase";
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  getDoc,
  addDoc,
} from "firebase/firestore";

import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    if (user) {
      if (user.role === "superadmin" || user.role === "admin") {
        navigate("/admin/user");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")));
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getUsers = async () => {
    try {
      const q = query(collection(db, "users"), where("role", "==", "Driver"));
      const querySnapshot = await getDocs(q);

      const fetchedUsers = await Promise.all(
        querySnapshot.docs.map(async (doc1) => {
          const user = doc1.data();
          let company = null;
          if (user.companyId) {
            const companyDoc = (
              await getDoc(doc(db, "companies", user.companyId))
            ).data();
            if (companyDoc) {
              company = companyDoc;
            }
          }
          return { id: doc1.id, ...user, company: company };
        }),
      );
      setUsers(fetchedUsers);
    } catch (e) {
      console.error("Error fetching conversations: ", e);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleDeleteUserBtn = (id) => {
    setDeleteUserId(id);
    setOpen(true);
  };
  const handleDeleteUser = () => {
    deleteDoc(doc(db, "users", deleteUserId)).then((querySnapshot) => {
      getUsers();
      setOpen(false);
    });
  };
  const handleChat = async (contact) => {
    try {
      // const currentUser = auth.currentUser;
      // if (!currentUser) {
      //   console.error("User not authenticated.");
      //   return;
      // }

      // Query to check if chat room exists
      const qry = query(
        collection(db, "rooms"),
        where("userIds", "array-contains", user.id),
      );

      const querySnapshot = await getDocs(qry);

      let chatRoom = null;
      querySnapshot.forEach((doc) => {
        const chatRoomData = doc.data();
        if (chatRoomData.userIds.includes(contact.id)) {
          chatRoom = { id: doc.id, ...chatRoomData };
        }
      });

      //if chat room not exist then create new chat room
      if (!chatRoom) {
        const room = {
          userIds: [user?.id, contact.id],
          name: contact.firstName, // Adjust based on your user structure
          // imageUrl: user.imageUrl, // Adjust based on your user structure
          type: "direct",
          lastMessage: "",
          createdAt: new Date(),
          metadata: null,
        };

        const newChatRoomRef = await addDoc(collection(db, "rooms"), room);
        chatRoom = { id: newChatRoomRef.id, ...room };
      }

      // Navigate to the chat room with roomId
      let finalData = { ...chatRoom, contact };
      navigate(`/admin/chats/${chatRoom.id}`, { state: { room: finalData } });
    } catch (error) {
      console.error("Error creating chat room:", error);
    }
  };

  return (
    <div className="mt-16">
      <div className="flex justify-between	">
        <h1 className="font-bold">Users</h1>
        <Link
          to="/admin/user/add"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Add User
        </Link>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Dot Number</TableCell>
                <TableCell>Company</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const updatedAt = new Date(row.updatedAt);

                  return (
                    <TableRow hover key={row.code}>
                      <TableCell>
                        {row.firstName} {row.lastName}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.dotNumber}</TableCell>
                      <TableCell>{row.company?.name}</TableCell>
                      <TableCell align="center">
                        {row.isActivated ? (
                          <Chip label="Enabled" color="success" size="small" />
                        ) : (
                          <Chip color="error" label="Disabled" size="small" />
                        )}
                      </TableCell>
                      <TableCell>{updatedAt.toLocaleString()}</TableCell>
                      <TableCell align="center">
                        <Button
                          color="secondary"
                          sx={{ marginRight: 1 }}
                          onClick={() => handleDeleteUserBtn(row.id)}
                          variant="contained"
                          size="small"
                        >
                          <Link to={"/admin/user/" + row.id + "/edit"}>
                            <i className="fa-solid fa-pen-to-square mr-1"></i>
                            Edit
                          </Link>
                        </Button>

                        <Button
                          color="error"
                          onClick={() => handleDeleteUserBtn(row.id)}
                          sx={{ marginRight: 1 }}
                          variant="contained"
                          size="small"
                        >
                          <i className="fa-solid fa-trash mr-1"></i> Delete
                        </Button>

                        <Button
                          color="primary"
                          onClick={() => handleChat(row)}
                          variant="contained"
                          size="small"
                        >
                          <i class="fa-solid fa-message mr-1"></i> Chat
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog className="relative z-10" open={open} onClose={setOpen}>
        <div
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Delete User
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to Delete user? User will be
                        permanently removed. This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  onClick={handleDeleteUser}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => setOpen(false)}
                  data-autofocus
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
