import React, { useState, useEffect } from "react";
import { auth, db } from "../../../firebase";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Divider, TextField } from "@mui/material";

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")));

  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      let userData = localStorage.getItem(user);
      if (userData) setuser(JSON.parse(userData));
    }

    const fetchusers = async () => {
      try {
        let queryData;
        user.role = user.role || "Driver";
        if (user.role == "admin") {
          queryData = query(collection(db, "users"));
        } else if (user.role == "subadmin") {
          queryData = query(
            collection(db, "users"),
            where("role", "==", "Driver"),
          );
        } else if (user.role == "Driver") {
          queryData = query(
            collection(db, "users"),
            where("companyId", "==", user.companyId),
          );
        }

        let users = await getDocs(queryData);

        let userData = users.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((doc) => doc.id != user.id);

        setContacts(userData);
      } catch (error) {
        console.error("Error getting companies:", error);
      }
    };
    fetchusers();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const normalizedSearchTerm = searchTerm
    .trim()
    .replace(/\s+/g, " ")
    .toLowerCase();

  const filteredContacts = normalizedSearchTerm
    ? contacts.filter((contact) =>
        contact.firstName.toLowerCase().includes(normalizedSearchTerm),
      )
    : contacts;

  const handleChat = async (contact) => {
    try {
      // const currentUser = auth.currentUser;
      // if (!currentUser) {
      //   console.error("User not authenticated.");
      //   return;
      // }

      // Query to check if chat room exists
      const qry = query(
        collection(db, "rooms"),
        where("userIds", "array-contains", user.id),
      );

      const querySnapshot = await getDocs(qry);

      let chatRoom = null;
      querySnapshot.forEach((doc) => {
        const chatRoomData = doc.data();
        if (chatRoomData.userIds.includes(contact.id)) {
          chatRoom = { id: doc.id, ...chatRoomData };
        }
      });

      //if chat room not exist then create new chat room
      if (!chatRoom) {
        const room = {
          userIds: [user.id, contact.id],
          // Adjust based on your user structure
          name: contact.firstName,
          type: "direct",
          lastMessage: "",
          createdAt: new Date(),
          metadata: null,
        };

        const newChatRoomRef = await addDoc(collection(db, "rooms"), room);
        chatRoom = { id: newChatRoomRef.id, ...room };
      }

      // Navigate to the chat room with roomId
      let finalData = { ...chatRoom, contact };
      navigate(`/admin/chats/${chatRoom.id}`, { state: { room: finalData } });
    } catch (error) {
      console.error("Error creating chat room:", error);
    }
  };

  return (
    <div className="mt-16 h-full">
      <div className="font-semibold text-lg max-w-screen-lg min-w-min m-auto pt-2">
        Contact
      </div>
      <div className="container relative flex items-center justify-center max-w-screen-lg min-w-min h-12 rounded-lg focus-within:shadow-lg m-auto mb-6 pt-6">
        <TextField
          id="filled-basic"
          label="Search Here..."
          value={searchTerm}
          className="w-full bg-white mx-auto"
          onChange={handleSearchChange}
          variant="filled"
          sx={{
            "& .MuiInputBase-input": {
              "&:focus": {
                boxShadow: "none !important",
              },
            },
          }}
        />
      </div>
      <div className="container m-auto max-w-screen-lg min-w-min bg-transparent overflow-auto h-full py-5 pb-28 shadow-2xl">
        {filteredContacts.length === 0 ? (
          <div>
            <h1 className="text-4xl text-gray-400 font-bold">
              No Contacts Found
            </h1>
          </div>
        ) : (
          filteredContacts.map((contact) => (
            <>
              <div
                onClick={() => handleChat(contact)}
                key={contact?.id}
                className="grid grid-cols-[3rem_1fr] items-center gap-3 px-5 py-4 hover:bg-gray-300 bg-gray-200 rounded-lg m-1"
              >
                <img
                  className="rounded-full bg-gray-800 w-12 h-12 object-cover"
                  src={
                    contact.imageUrl ||
                    "https://img.freepik.com/free-vector/user-blue-gradient_78370-4692.jpg?t=st=1718795593~exp=1718799193~hmac=19f5df7027682d16b18ce8503b5140c6b59bbfea40291d37ec96bb0b3ec87b10&w=740"
                  }
                />
                <div>
                  <h4 className="text-gray-800 font-medium text-base flex justify-between">
                    <span>{contact.firstName} </span>
                  </h4>
                  <p className="text-sm text-black/60 flex justify-between">
                    <span className="line-clamp-1 text-start  w-[95%]">
                      {contact.role}
                    </span>
                  </p>
                </div>
              </div>
            </>
          ))
        )}
      </div>
    </div>
  );
};

export default Contacts;
